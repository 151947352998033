var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('b-table',{staticClass:"width-auto",attrs:{"items":_vm.items,"fields":_vm.fields,"hover":""},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"head(permalink)",fn:function(ref){
var label = ref.label;
return [_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(label))]),_c('info',[_vm._v("숫자 형태로 자동으로 생성됩니다. url 을 구성할 때 사용됩니다. (예: cinesopa.kr/post/숫자 )")])]}},{key:"head(title_excerpt)",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(label))])]}},{key:"head(boardTitle)",fn:function(ref){
var label = ref.label;
return [_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(label))]),_c('info',[_vm._v(" 속해 있는 게시판입니다. ")])]}},{key:"head(c_date)",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(label))])]}},{key:"head(m_date)",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(label))])]}},{key:"cell(checkbox)",fn:function(row){return [_c('b-form-checkbox',{model:{value:(row.item.checked),callback:function ($$v) {_vm.$set(row.item, "checked", $$v)},expression:"row.item.checked"}})]}},{key:"cell(permalink)",fn:function(row){return [_c('div',{staticClass:"text-monospace"},[_vm._v(" "+_vm._s(row.item.id)+" ")])]}},{key:"cell(title_excerpt)",fn:function(row){return [_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(row.item.title)+" ")])]}},{key:"cell(c_date)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.formatSimpleDate(row.value))+" ")]}},{key:"cell(m_date)",fn:function(row){return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDateFromNow(row.value))+" ")]),_c('div',{staticClass:"Ttext-xs Ttext-gray-400"},[_vm._v(" "+_vm._s(_vm.formatDate(row.value))+" ")])]}}])}),(_vm.state.processing.get)?[_c('div',{staticClass:"Tflex Titems-center"},[_c('small-spinner',{staticClass:"Tmr-2"}),_c('span',[_vm._v("로딩중입니다.")])],1)]:_vm._e(),(!_vm.hasData && !_vm.state.processing.get)?_c('p',[_vm._v("글이 없습니다.")]):_vm._e(),_c('hr'),_c('b-button',{staticClass:"mx-1",attrs:{"disabled":!_vm.checkedAtleastOne,"variant":"danger"},on:{"click":_vm.removePost}},[_vm._v("삭제")]),_c('b-button',{staticClass:"mx-1",attrs:{"variant":"primary","to":{ name: 'PostNew' }}},[_vm._v("새로 추가")]),_c('hr'),_c('div',{staticClass:"pagination-wrapper"},[_c('b-pagination-nav',{attrs:{"link-gen":_vm.linkGen,"number-of-pages":_vm.totalPages,"align":"center","value":_vm.pageNav,"use-router":""}})],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"Tmb-3"},[_c('h2',{staticClass:"header Tmb-1"},[_c('span',[_vm._v("글 목록")])]),_c('span',[_vm._v(" 글을 수정하려면 "),_c('b',[_vm._v("행")]),_vm._v("을 클릭하세요 ")])])}]

export { render, staticRenderFns }